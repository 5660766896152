import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Slider from "@radix-ui/react-slider";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as Switch from "@radix-ui/react-switch";
import {
  ArrowRight,
  Check,
  Circle,
  DeviceMobile,
  EnvelopeSimple,
  Minus,
  MinusCircle,
  Plus,
  PlusCircle,
  RadioButton,
} from "@phosphor-icons/react";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPurchase } from "../lib/react-query/queryFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../core/store";
import { CreatePurchaseBody } from "../@types/AppTypes";
import { useSearchParams } from "react-router-dom";

const checkoutFormSchema = z.object({
  whatsapp: z.string().length(16, {
    message: "Informe um CPF válido",
  }),
  terms: z.literal<boolean>(true, {
    errorMap: () => ({
      message: "Você deve concordar com os termos e condições do sorteio!",
    }),
  }),
});

type CheckoutFormInputs = z.infer<typeof checkoutFormSchema>;

export type CheckoutBodyProps = {
  setFormStep: (data: string) => void;
  setQrcode: (data: string) => void;
  setPurchaseId: (data: string) => void;
  setDueDatePayment: (data: Date) => void;
};

export default function CheckoutBody({
  setFormStep,
  setQrcode,
  setPurchaseId,
  setDueDatePayment,
}: CheckoutBodyProps) {
  let [searchParams, _] = useSearchParams();
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const [checked, setChecked] = useState(true);
  const [totalTickets, setTotalTickets] = useState(30);
  const [selectedColor, setSelectedColor] = useState(5);
  const jackpot = jackpots?.filter((jack) => jack.type === "hour")[0];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<CheckoutFormInputs>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues: {
      terms: true,
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: createPurchase,
    onSuccess: (data) => {
      setFormStep("payment");
      setQrcode(data.qrcode);
      setPurchaseId(data.nid);
      setDueDatePayment(data.due_date);
    },
    onError: (e: AxiosError) => {
      toast.warn(`📢 Ops, aconteceu algum erro, confira as informações!`, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        type: "error",
        delay: 100,
      });
    },
  });
  console.log("total tickets", totalTickets);

  useEffect(() => {
    const user = localStorage.getItem("user") || null;

    if (user && getValues("whatsapp").length < 1) {
      setValue("whatsapp", user);
      reset({
        whatsapp: user,
        terms: true,
      });
    }
  }, [getValues("whatsapp"), totalTickets]);

  function handleImageChange(value: number) {
    const indexNumber = Math.min(
      Math.max(Math.ceil((value / 5) * Number(jackpot?.special_price)), 1),
      10
    );
    const index =
      indexNumber < 10 ? "0" + indexNumber.toString() : indexNumber.toString();
    return `/jackpot/${index}.png`;
  }

  function addToTotal(value: number) {
    setTotalTickets(totalTickets + value);
  }

  async function handleCheckout(data: CheckoutFormInputs): Promise<void> {
    if (selectedColor > 3) {
      toast.warn(`📢 Ops, você deve escolher uma cor para continuar!`, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        type: "error",
        delay: 100,
      });
      return;
    }
    localStorage.setItem("user", data.whatsapp);
    const affiliateCodeFromLocalStoage =
      localStorage.getItem("affiliateCode") || null;
    const affiliateCodeFromURL = searchParams.get("src");
    if (affiliateCodeFromURL) {
      localStorage.setItem("affiliateCode", affiliateCodeFromURL);
    }
    const whatsappNumber = data.whatsapp.replace(/\D/g, "");

    //try catch purchase;

    const purchaseBody: CreatePurchaseBody = {
      jackpot_id: jackpot?.id!,
      whatsapp: whatsappNumber,
      tickets_amount: totalTickets,
      price: Number(totalTickets * Number(jackpot?.special_price)),
      selected_color: selectedColor,
      affiliate_code: affiliateCodeFromURL
        ? affiliateCodeFromURL
        : affiliateCodeFromLocalStoage
        ? affiliateCodeFromLocalStoage
        : null,
    };
    mutate(purchaseBody);
  }

  function handleChangeTotal(value: string) {
    console.log(value);
    setTotalTickets(Number(value));
  }

  return (
    <form
      className="flex flex-col w-full h-full gap-4 text-black text-2xl font-semibold"
      onSubmit={handleSubmit(handleCheckout)}
    >
      <div className="flex flex-col w-full h-fit gap-1">
        <section className="flex flex-col items-start justify-between w-full  h-fit gap-2">
          <p className="font-normal text-center text-[13px] text-[#474747] lg:text-[15px] leading-tight">
            Preencha os dados abaixo para concorrer ao sorteio
          </p>

          <div className="flex flex-col w-full items-center justify-center rounded-lg bg-[#F2F2F2] px-3 py-3">
            <p className="font-medium text-center text-[18px] lg:text-lg text-[#474747] leading-tight">
              Valor da participação
            </p>
            <div className="flex flex-row w-fit items-center justify-center gap-2 border-b-2 border-[#03A344]">
              <p className="text-[29px] font-normal text-[#03A344]">R$</p>
              <div className="flex flex-row w-full">
                <input
                  className={`flex ${
                    totalTickets > 9999
                      ? "max-w-[145px]"
                      : totalTickets > 999
                      ? "max-w-[114px]"
                      : totalTickets > 99
                      ? "max-w-[88px]"
                      : totalTickets > 9
                      ? "max-w-14"
                      : "max-w-8"
                  } text-end text-[50px] leading-[60px] lg:text-5xl font-bold text-transparent bg-clip-text bg-[#03A344] focus:ring-green-700 focus:outline-green-700`}
                  value={totalTickets}
                  type="text"
                  maxLength={5}
                  onChange={(e) =>
                    handleChangeTotal(e.target.value.replace(/\D/g, ""))
                  }
                />
                <p className="text-[50px] mt-[3px] leading-[60px] lg:mt-[7px] lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#03A344] to-[#E7D289]">
                  ,00
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row w-full h-fit items-center justify-between gap-2 mt-2">
            <button
              className="w-36 lg:w-44 text-[#03A344] hover:text-white text-sm lg:text-base rounded-[100px] px-4 py-2 font-medium border-2 border-[#03A344] hover:bg-[#028F3C] focus:outline-none focus:ring-2 focus:ring-[#03A344] focus:ring-opacity-50 transition-all"
              type="button"
              onClick={() => addToTotal(1)}
            >
              +1
            </button>
            <button
              className="w-36 lg:w-44 text-[#03A344] hover:text-white text-sm lg:text-base rounded-[100px] px-4 py-2 font-medium border-2 border-[#03A344] hover:bg-[#028F3C] focus:outline-none focus:ring-2 focus:ring-[#03A344] focus:ring-opacity-50 transition-all"
              type="button"
              onClick={() => addToTotal(10)}
            >
              +10
            </button>
            <button
              className="w-36 lg:w-44 text-[#03A344] hover:text-white text-sm lg:text-base rounded-[100px] px-4 py-2 font-medium border-2 border-[#03A344] hover:bg-[#028F3C] focus:outline-none focus:ring-2 focus:ring-[#03A344] focus:ring-opacity-50 transition-all"
              type="button"
              onClick={() => addToTotal(50)}
            >
              +50
            </button>
          </div>

          <div className="flex flex-col w-full lg:flex-row lg:items-end gap-3 mt-3 lg:mt-5">
            <div className="flex flex-col w-full h-fit gap-2 flex-1">
              <div className="flex flex-col lg:flex-row-reverse w-full items-center justify-between gap-2 lg:gap-5">
                <div className="flex flex-col w-full items-center lg:items-start gap-1">
                  <span className="text-center font-semibold text-[27px] lg:text-[27px]">
                    Sorte de Quatro Cores
                  </span>
                  <span className="text-center lg:text-start font-normal text-[11px] lg:text-[15px] text-[#454545] leading-[18px]">
                    Escolha sua cor da sorte em nosso trevo de quatro folhas e{" "}
                    <strong>concorra ao dobro do prêmio!</strong> Simples,
                    rápido e divertido. Participe!
                  </span>
                  <span className="hidden lg:block font-normal text-[11px] lg:text-[15px] text-[#454545]">
                    Cor escolhida:{" "}
                    <span
                      className={`font-bold text-[12px] lg:text-[15px] ${
                        selectedColor === 0
                          ? "text-yellow-500"
                          : selectedColor === 1
                          ? "text-green-500"
                          : selectedColor === 2
                          ? "text-blue-500"
                          : selectedColor === 3
                          ? "text-orange-500"
                          : "text-[#454545]"
                      }`}
                    >
                      {selectedColor === 0
                        ? "Amarelo"
                        : selectedColor === 1
                        ? "Verde"
                        : selectedColor === 2
                        ? "Azul"
                        : selectedColor === 3
                        ? "Laranja"
                        : "Nenhuma"}
                    </span>
                  </span>
                </div>
                <div className="flex flex-col min-w-[100px] max-w-[100px] min-h-[100px] max-h-[100px] lg:min-w-[120px] lg:max-w-[120px] lg:min-h-[120px] lg:max-h-[120px]">
                  <div className="flex flex-row w-full h-1/2">
                    <button
                      type="button"
                      className="relative min-w-[50px] max-w-[50px] min-h-[50px] max-h-[50px] lg:min-w-[60px] lg:max-w-[60px] lg:min-h-[60px] lg:max-h-[60px] hover:scale-[1.02] transition-all group overflow-hidden"
                      onClick={() => setSelectedColor(0)}
                    >
                      {selectedColor === 0 ? (
                        <RadioButton
                          className="absolute top-[65%] left-[65%] translate-x-[-65%] translate-y-[-65%] z-40 text-white"
                          weight="fill"
                        />
                      ) : (
                        <Circle
                          className="absolute top-[65%] left-[65%] translate-x-[-65%] translate-y-[-65%] z-40 text-white"
                          weight="bold"
                        />
                      )}
                      <svg
                        className="w-full h-full text-[#DDBE55] fill-current group-hover:text-yellow-500 -rotate-90"
                        viewBox="0 0 128 133"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.1 0.964097C53.0158 1.02832 63.4664 5.39314 71.1852 13.112C78.9041 20.8308 83.2689 31.2814 83.3331 42.1972L83.4048 48.3284L89.4822 48.3464C100.096 48.8252 110.117 53.3786 117.458 61.0589C124.8 68.7392 128.897 78.9548 128.897 89.5795C128.897 100.204 124.8 110.42 117.458 118.1C110.117 125.78 100.096 130.334 89.4822 130.813L4.61363 130.92C3.60788 130.913 2.64538 130.51 1.9342 129.799C1.22301 129.088 0.82026 128.125 0.813004 127.12L0.813015 42.251C0.863243 31.3165 5.22923 20.8442 12.9612 13.1122C20.6931 5.38031 31.1654 1.01432 42.1 0.964097Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>

                    <button
                      type="button"
                      className="relative min-w-[50px] max-w-[50px] min-h-[50px] max-h-[50px] lg:min-w-[60px] lg:max-w-[60px] lg:min-h-[60px] lg:max-h-[60px] hover:scale-[1.02] transition-all group overflow-hidden"
                      onClick={() => setSelectedColor(1)}
                    >
                      {selectedColor === 1 ? (
                        <RadioButton
                          className="absolute top-[65%] left-[35%] translate-x-[-35%] translate-y-[-65%] z-40 text-white"
                          weight="fill"
                        />
                      ) : (
                        <Circle
                          className="absolute top-[65%] left-[35%] translate-x-[-35%] translate-y-[-65%] z-40 text-white"
                          weight="bold"
                        />
                      )}
                      <svg
                        className="w-full h-full text-[#39B54A] fill-current group-hover:text-green-600"
                        viewBox="0 0 128 133"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.1 0.964097C53.0158 1.02832 63.4664 5.39314 71.1852 13.112C78.9041 20.8308 83.2689 31.2814 83.3331 42.1972L83.4048 48.3284L89.4822 48.3464C100.096 48.8252 110.117 53.3786 117.458 61.0589C124.8 68.7392 128.897 78.9548 128.897 89.5795C128.897 100.204 124.8 110.42 117.458 118.1C110.117 125.78 100.096 130.334 89.4822 130.813L4.61363 130.92C3.60788 130.913 2.64538 130.51 1.9342 129.799C1.22301 129.088 0.82026 128.125 0.813004 127.12L0.813015 42.251C0.863243 31.3165 5.22923 20.8442 12.9612 13.1122C20.6931 5.38031 31.1654 1.01432 42.1 0.964097Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="flex flex-row w-full h-1/2 ">
                    <button
                      type="button"
                      className="relative min-w-[50px] max-w-[50px] min-h-[50px] max-h-[50px] lg:min-w-[60px] lg:max-w-[60px] lg:min-h-[60px] lg:max-h-[60px] hover:scale-[1.02] transition-all group overflow-hidden"
                      onClick={() => setSelectedColor(2)}
                    >
                      {selectedColor === 2 ? (
                        <RadioButton
                          className="absolute top-[35%] left-[65%] translate-x-[-65%] translate-y-[-35%]  z-40 text-white"
                          weight="fill"
                        />
                      ) : (
                        <Circle
                          className="absolute top-[35%] left-[65%] translate-x-[-65%] translate-y-[-35%] z-40 text-white"
                          weight="bold"
                        />
                      )}
                      <svg
                        className="w-full h-full text-[#0078E8] fill-current group-hover:text-blue-600 rotate-180"
                        viewBox="0 0 128 132"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.1 0.964097C53.0158 1.02832 63.4664 5.39314 71.1852 13.112C78.9041 20.8308 83.2689 31.2814 83.3331 42.1972L83.4048 48.3284L89.4822 48.3464C100.096 48.8252 110.117 53.3786 117.458 61.0589C124.8 68.7392 128.897 78.9548 128.897 89.5795C128.897 100.204 124.8 110.42 117.458 118.1C110.117 125.78 100.096 130.334 89.4822 130.813L4.61363 130.92C3.60788 130.913 2.64538 130.51 1.9342 129.799C1.22301 129.088 0.82026 128.125 0.813004 127.12L0.813015 42.251C0.863243 31.3165 5.22923 20.8442 12.9612 13.1122C20.6931 5.38031 31.1654 1.01432 42.1 0.964097Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>

                    <button
                      type="button"
                      className="relative min-w-[50px] max-w-[50px] min-h-[50px] max-h-[50px] lg:min-w-[60px] lg:max-w-[60px] lg:min-h-[60px] lg:max-h-[60px] hover:scale-[1.02] transition-all group overflow-hidden"
                      onClick={() => setSelectedColor(3)}
                    >
                      {selectedColor === 3 ? (
                        <RadioButton
                          className="absolute top-[35%] left-[35%] translate-x-[-35%] translate-y-[-35%]  z-50 text-white"
                          weight="fill"
                        />
                      ) : (
                        <Circle
                          className="absolute top-[35%] left-[35%] translate-x-[-35%] translate-y-[-35%] z-50 text-white"
                          weight="bold"
                        />
                      )}
                      <svg
                        className="w-full h-full text-[#EE7219] fill-current group-hover:text-orange-500 rotate-90"
                        viewBox="0 0 129 132"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M42.1 0.964097C53.0158 1.02832 63.4664 5.39314 71.1852 13.112C78.9041 20.8308 83.2689 31.2814 83.3331 42.1972L83.4048 48.3284L89.4822 48.3464C100.096 48.8252 110.117 53.3786 117.458 61.0589C124.8 68.7392 128.897 78.9548 128.897 89.5795C128.897 100.204 124.8 110.42 117.458 118.1C110.117 125.78 100.096 130.334 89.4822 130.813L4.61363 130.92C3.60788 130.913 2.64538 130.51 1.9342 129.799C1.22301 129.088 0.82026 128.125 0.813004 127.12L0.813015 42.251C0.863243 31.3165 5.22923 20.8442 12.9612 13.1122C20.6931 5.38031 31.1654 1.01432 42.1 0.964097Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <span className="lg:hidden font-normal text-[11px] lg:text-[15px] text-[#454545]">
                  Cor escolhida:{" "}
                  <span
                    className={`font-bold text-[12px] lg:text-[15px] ${
                      selectedColor === 0
                        ? "text-yellow-500"
                        : selectedColor === 1
                        ? "text-green-500"
                        : selectedColor === 2
                        ? "text-blue-500"
                        : selectedColor === 3
                        ? "text-orange-500"
                        : "text-[#454545]"
                    }`}
                  >
                    {selectedColor === 0
                      ? "Amarelo"
                      : selectedColor === 1
                      ? "Verde"
                      : selectedColor === 2
                      ? "Azul"
                      : selectedColor === 3
                      ? "Laranja"
                      : "Nenhuma"}
                  </span>
                </span>
              </div>

              <div className="flex flex-col flex-1 lg:mt-3">
                <label className="text-[#344054] text-xs md:text-sm font-poppins font-semibold tracking-wide">
                  Celular*
                </label>
                <div className="relative">
                  <InputMask
                    mask="(99) 9 9999-9999"
                    type="text"
                    placeholder="(DDD) X XXXX-XXXX"
                    autoComplete="off"
                    className={`appearance-none border pl-12 bg-white placeholder:text-black/40  ${
                      errors.whatsapp ? "border-red-500" : "border-[#D0D5DD]"
                    } shadow-sm focus:shadow-md focus:placeholder-black/40  transition  rounded w-full py-[10px] text-xs md:text-sm placeholder:text-xs text-[#667085]  leading-tight focus:outline-none focus:ring-black/70 focus:shadow-outline`}
                    {...register("whatsapp")}
                  />
                  <div className="absolute left-0 inset-y-0 flex items-center">
                    <DeviceMobile className="h-7 w-7 ml-2 text-[#667085]  p-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="flex flex-col lg:flex-row w-full items-center justify-between h-fit gap-2 lg:gap-1 ">
        <Checkbox.Root
          className="flex flex-row w-full lg:w-fit items-center gap-2 group focus:outline-none"
          checked={checked}
          {...(register("terms"),
          {
            onCheckedChange() {
              setChecked((prevState) => !prevState);
              setValue("terms", !checked);
            },
            defaultValue: "none",
          })}
        >
          <div
            className={`w-4 h-4 lg:w-5 lg:h-5 rounded flex items-center justify-center p-[6px] lg:p-[10px] bg-white border-2 ${
              errors.terms ? "border-red-500" : "border-quaternary"
            }  group-data-[state=checked]:bg-green-600 group-data-[state=checked]:border-green-600 transition-colors group-focus:ring-2 group-focus:ring-green-600 group-focus:ring-offset-2 group-focus:ring-offset-background`}
          >
            <Checkbox.Indicator>
              <Check size={16} className="text-white" weight="bold" />
            </Checkbox.Indicator>
          </div>

          <span
            className={`${
              false ? "text-red-500" : "text-gray-600"
            } text-left text-[9px] lg:text-[12px] font-normal font-poppins leading-tight`}
          >
            Ao clicar em Prosseguir, você está de acordo com os termos do
            sorteio.
          </span>
        </Checkbox.Root>

        <button
          type="submit"
          className="flex flex-row justify-center items-center py-4 px-[70px] lg:px-16 text-white text-lg font-bold font-poppins uppercase bg-gradient-to-r from-[#EE7219] to-[#FBBA00] hover:bg-green-600 rounded-full gap-4"
        >
          {isPending && (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-7 h-7 mr-2 text-gray-200 animate-spin fill-action3"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          Prosseguir
          <ArrowRight className="w-4 h-4" weight="bold" />
        </button>
      </div>
    </form>
  );
}
