import { InstagramLogo, WhatsappLogo } from "@phosphor-icons/react";

export default function Footer() {
  return (
    <section className="flex flex-col lg:flex-row w-full h-fit items-center justify-between gap-1 px-4 xl:px-32 lg:px-5 py-8 lg:py-12 bg-[#E9F1EF]">
      <div className="flex flex-col w-full lg:flex-row items-center gap-2 lg:gap-3 xl:gap-5">
        <a
          className="flex flex-row w-full lg:w-fit items-center justify-center py-2 px-5 hover:bg-[#03A344] text-[14px] lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="https://bit.ly/pixhora-suporte"
          target="_blank"
        >
          Suporte
        </a>
        <a
          className="flex flex-row w-full lg:w-fit items-center justify-center py-2 px-5 hover:bg-[#03A344] text-[14px] lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="/termos"
        >
          Termos
        </a>
        <a
          className="flex flex-row w-full lg:w-fit items-center justify-center py-2 px-5 hover:bg-[#03A344] text-[14px] lg:text-base text-[#3D3D3D] font-semibold hover:text-white rounded-[100px] border-2 border-[#03A344]"
          href="/faq"
        >
          Perguntas Frequentes
        </a>
      </div>
      <div className="flex flex-col w-full lg:flex-row items-center gap-2 lg:gap-3 xl:gap-5">
        <div className="flex flex-col h-full gap-3 items-center justify-start">
          <span className="text-[#2C2C2C] text-[10px] lg:text-xs">
            Desenvolvido por:
          </span>
          <div className="flex items-center h-full">
            <img
              src={"/soft2win.png"}
              alt="soft2win"
              className="w-[100px] lg:w-[150px]"
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 items-center">
          <img src={"/google_safe.webp"} alt="google" className="w-[130px]" />

          <a
            href="https://www.reclameaqui.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-1 bg-white p-1 rounded-[4px] border border-[#a4c929]"
          >
            <img
              src={"/otimoICON.svg"}
              alt="otimo"
              className="w-[38px] h-[38px]"
            />
            <div className="flex flex-col pt-[3px]">
              <span className="tracking-wide text-[13px] text-[#2C2C2C] ">
                ÓTIMO
              </span>
              <img
                src={"/reclame-aqui-vector-logo.svg"}
                alt="reclameaqui"
                className="w-[80px] -mt-[2px]"
              />
            </div>
          </a>
        </div>
        <div className="flex flex-row items-center gap-6">
          <div className="flex flex-row items-center gap-6">
            <a
              href="https://www.instagram.com/pixporhora/"
              target="_blank"
              className="appearance-none bg-gradient-to-br from-[#03A344] via-[#88BE6C]  to-[#E7D289] rounded-full p-2"
            >
              <InstagramLogo
                className="text-white w-5 h-5 lg:w-6 lg:h-6 hover:w-5 hover:h-5 lg:hover:w-7 lg:hover:h-7"
                weight="regular"
              />
            </a>
            <a
              href="https://bit.ly/pixhora-suporte"
              target="_blank"
              className="appearance-none bg-gradient-to-br from-[#03A344] via-[#88BE6C]  to-[#E7D289] rounded-full p-2"
            >
              <WhatsappLogo
                className="text-white w-5 h-5 lg:w-6 lg:h-6 hover:w-5 hover:h-5 lg:hover:w-7 lg:hover:h-7"
                weight="regular"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
