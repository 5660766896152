import { ProfileResponse } from "@/@types/AppTypes";
import { InstagramLogo, WhatsappLogo } from "@phosphor-icons/react";
import { useState } from "react";
import { WhatsappShareButton } from "react-share";

export type ReferralTabProps = {
  profile: ProfileResponse;
};

export default function ReferralTab({ profile }: ReferralTabProps) {
  const [isCopied, setIsCopied] = useState(false);
  let referralLink = `${window.location.href.slice(
    0,
    window.location.href.lastIndexOf("/")
  )}/?src=${profile?.affiliate_code}`;

  function handleCopyLink() {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
  }
  return (
    <section className="flex flex-col w-full gap-3">
      <div className="flex flex-col w-full gap-3 items-center lg:items-start justify-center lg:justify-start text-center lg:text-start">
        <span className="text-[19px] lg:text-[25px] font-extrabold text-[#393939]">
          Convide e Ganhe!
        </span>

        <span className="text-[15px] lg:text-[17px] font-regular text-[#5E5E5E]">
          Convide seus amigos e ganhe benefícios! Para cada amigo que gastar os
          primeiros R$ 20,00, você recebe R$ 10,00.
        </span>
      </div>

      <div className="flex w-full flex-col lg:flex-row items-center gap-6">
        <div className="flex flex-row items-center justify-center w-full lg:w-1/2 p-3 lg:p-6 gap-4 border-2 border-[#E0E0E0] rounded-xl">
          <div className="px-3 py-[2px] bg-[#C1C1C1] rounded-full">
            <svg
              className="text-white w-[21px] h-[21px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z"></path>
            </svg>
          </div>

          <span className="font-bold text-[31px] text-[#595959]">
            {profile?.affiliate_users.length}
          </span>

          <span className="font-medium text-[13px] text-[#383838] tracking-[1px]">
            INDICAÇÕES
          </span>
        </div>

        <div className="flex flex-row items-center justify-center w-full lg:w-1/2 p-3 lg:p-6 gap-4 border-2 border-[#E0E0E0] rounded-xl">
          <div className="px-3 py-[2px] bg-[#6DC032] rounded-full">
            <svg
              className="text-white w-[21px] h-[21px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM17.7929 19.9142L21.3284 16.3787L22.7426 17.7929L17.7929 22.7426L14.2574 19.2071L15.6716 17.7929L17.7929 19.9142Z"></path>
            </svg>
          </div>

          <span className="font-bold text-[31px] text-[#595959]">
            {profile?.paid_affiliates}
          </span>

          <span className="font-medium text-[13px] text-[#383838] tracking-[1px]">
            EFETIVADAS
          </span>
        </div>
      </div>

      <div className="flex w-full flex-col lg:flex-col-reverse items-center gap-3 lg:gap-6">
        <span className="text-xs lg:text-[15px] font-regular text-[#5E5E5E] text-center lg:text-start">
          Você pode enviar o seu link diretamente para os seus amigos ou
          compartilhá-lo nas redes sociais.
        </span>
        <div className="flex flex-col lg:flex-row lg:items-center w-full gap-2 border-2 border-[#C4C4C4] rounded-xl lg:rounded-full p-2">
          <span className="flex-1 w-full  text-[11px] lg:text-[14px] font-medium tracking-[1px] text-black truncate">
            {referralLink.toUpperCase()}
          </span>
          <div className="flex flex-row items-center justify-between gap-4">
            <button
              type="button"
              onClick={handleCopyLink}
              className={` w-full gap-3 px-4 py-[14px] lg:py-[6px] tracking-[1px] text-white font-medium text-[13px] ${
                isCopied
                  ? "bg-[#03A344]"
                  : "bg-gradient-to-br from-[#03A344] via-[#88BE6C] to-[#E7D289] hover:from-[#E7D289] hover:to-[#03A344]"
              }  hover:font-semibold rounded-full transition-all`}
            >
              {isCopied ? "LINK COPIADO" : "COPIAR LINK"}
            </button>
            <div className="flex flex-row items-center justify-between gap-4">
              <WhatsappShareButton url={referralLink}>
                <div className="flex items-center justify-center w-10 h-10 p-[3px] bg-white rounded-full shadow-brand hover:border hover:border-black/20">
                  <WhatsappLogo
                    className="text-[#121212] w-[22px] h-[22px]"
                    weight="bold"
                  />
                </div>
              </WhatsappShareButton>

              <a
                className="flex items-center justify-center w-10 h-10 p-[3px] bg-white rounded-full shadow-brand"
                href="https://www.instagram.com/pixporhora/"
                target="_blank"
              >
                <InstagramLogo
                  className="text-[#121212] min-w-[22px] min-h-[22px]"
                  weight="bold"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
