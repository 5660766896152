import {
  CaretDoubleUp,
  Ticket,
  ListChecks,
  RocketLaunch,
  Trophy,
} from "@phosphor-icons/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { HowItWorksSectionProps } from "@/@types/AppTypes";

export default function HowItWorksSection({
  setOpenCheckoutModal,
}: HowItWorksSectionProps) {
  return (
    <section className="flex flex-col w-full h-fit items-center gap-1 mt-14 lg:mt-10 p-4">
      <p className="text-center text-2xl lg:text-4xl font-bold uppercase text-[#2B444C]">
        Para participar é simples
      </p>

      <div className="flex flex-col lg:flex-row lg:w-full lg:h-fit items-center justify-center lg:justify-start gap-16 mt-20 lg:overflow-x-auto lg:overflow-y-hidden lg:scrollbar lg:py-7 lg:ml-36 lg:pl-1 lg:pr-24">
        <div className="flex flex-col lg:flex-row  items-center justify-center lg:justify-start gap-14 lg:gap-16 2xl:gap-8">
          <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-14 lg:gap-8">
            <div className="relative flex flex-col items-center justify-start p-6 gap-4 w-[330px] lg:w-[450px] h-[290px] bg-white shadow-2xl rounded-2xl">
              <div className="flex min-w-20 min-h-20 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg rounded-full p-1">
                <RocketLaunch className="text-white w-12 h-12" weight="fill" />
              </div>

              <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
                <p className="font-semibold text-2xl text-[#6DC032]">
                  Como funciona?
                </p>
                <p className="text-center text-sm max-w-[255px] text-[#666666]">
                  A cada hora, um sorteio emocionante! O prêmio é pago via PIX,
                  o mesmo utilizado para a aposta. Rápido e prático!
                </p>
              </div>
            </div>

            <div className="relative flex flex-col items-center justify-start p-6 gap-4 w-[330px] lg:w-[450px] h-[290px] bg-white shadow-2xl rounded-2xl">
              <div className="flex min-w-20 min-h-20 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg  rounded-full p-1">
                <FontAwesomeIcon
                  icon={faPix}
                  className="text-white text-[48px]"
                />
              </div>

              <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
                <p className="font-semibold text-2xl text-[#6DC032]">
                  24 chances diárias
                </p>
                <p className="text-center text-sm max-w-[255px] text-[#666666]">
                  Por cada participação de{" "}
                  <span className="font-bold">R$ 1,00</span>, ganhe um ticket
                  para o sorteio da hora. Escolha uma cor e, se sorteada, dobre
                  seu PIX!
                </p>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col items-center justify-start p-6 gap-4 w-[330px] lg:w-[450px] h-[290px] bg-white shadow-2xl rounded-2xl">
            <div className="flex min-w-20 min-h-20 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg rounded-full p-1">
              <Ticket className="text-white w-12 h-12" weight="fill" />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-full h-fit">
              <p className="font-semibold text-2xl text-[#6DC032]">
                Ganhadores!
              </p>
              <p className="text-center text-sm max-w-[255px] text-[#666666]">
                A cada R$ 20,00 incremente seus tickets para os sorteios PIX
                DIA, PIX SEMANA, PIX MÊS e PIX ANO. Mais participações, mais
                chances!
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-center gap-14 lg:gap-8">
          <div className="relative flex flex-col items-center justify-start p-6 gap-4 w-[330px] lg:w-[450px] h-[290px] bg-white shadow-2xl rounded-2xl">
            <div className="flex min-w-20 min-h-20 items-center justify-center bg-gradient-to-br from-[#03A344] to-[#E7D289] shadow-lg  rounded-full p-1">
              <ListChecks className="text-white w-12 h-12" weight="fill" />
            </div>
            <p className="font-semibold text-2xl text-[#6DC032]">
              Como participar?
            </p>
            <p className="text-center text-sm max-w-[255px] text-[#666666]">
              Participe a partir de R$ 1,00, informe seus dados de contato e
              pague via PIX, que também será usado para o prêmio.
            </p>
          </div>

          <div className="relative flex flex-col items-center justify-start p-6 gap-4 w-[330px] lg:w-[450px] h-[290px] bg-gradient-to-r from-[#03A344] to-[#E7D289] shadow-2xl rounded-2xl">
            <div className="flex min-w-20 min-h-20 items-center justify-center bg-white shadow-lg  rounded-full p-1">
              <Trophy className="text-green-500 w-12 h-12" weight="fill" />
            </div>
            <p className="font-semibold text-2xl text-white">
              Aguarde o sorteio
            </p>
            <p className="text-center text-[15px] text-white lg:max-w-[280px]">
              Os sorteios terão vencedores anunciados logo ao seu fim, fique
              ligado pois as participações se encerram nos últimos minutos!
            </p>

            <div className="flex flex-row items-center gap-4 py-5 px-6 mt-6 rounded-full text-white text-[16px] font-bold uppercase" />
            <img
              src={"/3D-CURRENCY2 3.png"}
              alt="Logo"
              width={100}
              height={65}
              className="absolute top-28 -right-12 w-20 lg:w-24 h-14 lg:h-16"
            />
            <img
              src={"/3D-CURRENCY 4.png"}
              alt="Logo"
              width={90}
              height={90}
              className="absolute -left-6 lg:-left-3 -bottom-7 lg:-bottom-6"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
