import { Check, CheckFat } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

export type PaymentConfirmedProps = {
  purchaseId: string;
  setIsOpen: (data: boolean) => void;
  setFormStep: (data: string) => void;
};

export default function PaymentConfirmed({
  purchaseId,
  setIsOpen,
  setFormStep,
}: PaymentConfirmedProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-fit items-center justify-between transition-all gap-8">
      <div className="flex flex-col w-full h-fit items-center justify-center transition-all px-4 lg:px-10 py-6 gap-5 rounded">
        <div className="flex flex-row w-fit items-center gap-3">
          <div className="rounded-full p-2 border-2 border-[#03A344]">
            <Check className="text-[#03A344] w-8 h-8" weight="bold" />
          </div>
          <span className="text-[#03A344] text-4xl font-bold">Obrigado!</span>
        </div>

        <span className="text-[#03A344] text-center font-medium tracking-tight text-xs lg:text-base">
          Pronto! Confirmamos o pagamento da sua aposta e a partir de agora você
          está concorrendo ao sorteio!
        </span>
        <span className="text-[#03A344] text-center font-medium tracking-tight text-xs lg:text-base">
          Caso você seja o vencedor, o prêmio será enviado por PIX pela mesma
          chave no qual o pagamento da aposta foi realizado.
        </span>
        <span className="text-[#03A344] text-center font-normal tracking-tighter text-xs lg:text-base">
          Você poderá acompanhar o andamento do sorteio clicando no botão
          abaixo:
        </span>
      </div>

      <div className="flex flex-row w-full items-center justify-between lg:p-3">
        <button
          className="flex items-center justify-center px-3 lg:px-5 py-2 rounded lg:rounded-lg border-2 border-[#03A344] text-[#03A344] text-xs lg:text-base"
          onClick={() => {
            setIsOpen(false);
            setFormStep("");
          }}
        >
          Voltar à tela inicial
        </button>

        <button
          className="flex items-center justify-center px-3 lg:px-5 py-2 rounded lg:rounded-lg bg-[#03A344] border-2 border-[#03A344] text-white text-xs lg:text-base"
          onClick={() => {
            setIsOpen(false);
            setFormStep("");
          }}
        >
          Acompanhar sorteio
        </button>
      </div>
    </div>
  );
}
