import { ProfileResponse } from "@/@types/AppTypes";
import {
  CaretDown,
  CaretUp,
  CurrencyDollar,
  CurrencyDollarSimple,
  Gift,
  InstagramLogo,
  Ticket,
  UserCircle,
  UsersFour,
  WhatsappLogo,
  X,
} from "@phosphor-icons/react";
import { WhatsappShareButton } from "react-share";
import * as Popover from "@radix-ui/react-popover";
import * as Tabs from "@radix-ui/react-tabs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store";
import MyTicketsModal from "../components/modals/MyTicketsModal";
import ReferralTab from "./ReferralTab";
import ToReceiveTab from "./ToReceiveTab";
import ReceivedTab from "./ReceivedTab";

export type TopMenuProps = {
  openMyTicketsModal: boolean;
  setOpenMyTicketsModal: (data: boolean) => void;
  profile?: ProfileResponse | null;
};

export default function TopMenu({
  openMyTicketsModal = false,
  setOpenMyTicketsModal,
  profile,
}: TopMenuProps) {
  const jackpots = useSelector(
    (state: RootState) => state.jackpots.openJackpot
  );
  const name = localStorage.getItem("name");

  function handleModalOpen() {
    if (openMyTicketsModal) {
      setOpenMyTicketsModal(false);
    } else {
      setOpenMyTicketsModal(true);
    }
  }

  return (
    <main
      className={`flex flex-col-reverse lg:flex-row w-full items-center justify-around lg:h-20 ${
        openMyTicketsModal ? "bg-[#002E13]/90" : "bg-[#002E13]/60"
      } `}
    >
      <MyTicketsModal
        isOpen={openMyTicketsModal}
        setIsOpen={setOpenMyTicketsModal}
        profile={profile!}
        jackpots={jackpots!}
        modalJackpots={jackpots!}
      />
      <div className="flex w-full lg:w-fit flex-col lg:flex-row p-4 gap-2 lg:gap-8 items-center justify-center lg:justify-around ">
        <div className="flex w-full lg:w-fit flex-row items-center justify-between lg:justify-center lg:gap-8">
          <div className="flex flex-row gap-1 items-center justify-center">
            <UserCircle className="text-white w-6 h-6" weight="bold" />
            <span className="text-[#C4FA00] font-semibold text-[16px] lg:text-[21px]">
              Olá, {name ? name.split(" ")[0] : "Cliente"}!
            </span>
          </div>

          <div className="hidden lg:flex flex-row items-center justify-around h-10 py-2 px-6 bg-[#092E0F] rounded-full">
            <CurrencyDollarSimple
              className="text-[#C4FA00] w-6 h-6"
              weight="bold"
            />

            <div className="flex flex-row items-center justify-center gap-1 text-white">
              <span>Meus Ganhos</span>
              <div className="flex flex-col gap-[2px]">
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="font-semibold">R$</p>
                  <p className="font-semibold">
                    {new Intl.NumberFormat("pt-br", {
                      style: "currency",
                      currency: "BRL",
                      currencyDisplay: "code",
                    })
                      .format(
                        Number(profile ? Number(profile?.total_purchases) : 0)
                      )
                      .replace("BRL", "")
                      .trim()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="flex flex-row items-center justify-between py-2 px-4 gap-5 h-10 bg-[#C4FA00] rounded-full"
            onClick={handleModalOpen}
          >
            <div className="flex flex-row gap-1 items-center justify-center">
              <Ticket className="text-[#002F13] w-5 h-5" weight="bold" />
              <span className="text-sm text-[#002F13] font-medium">
                Meus Tickets
              </span>
            </div>
          </button>
        </div>

        <div className="flex lg:hidden w-full flex-row items-center justify-center h-10 py-2 px-6 gap-2 bg-[#092E0F] rounded-full">
          <CurrencyDollarSimple
            className="text-[#C4FA00] w-6 h-6"
            weight="bold"
          />

          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <span className="text-sm">Meus Ganhos</span>
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-row items-center justify-center gap-2">
                <p className="font-semibold">
                  {new Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    Number(profile ? Number(profile?.total_purchases) : 0)
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="flex flex-row w-full lg:w-fit h-12 lg:h-10 items-center justify-center py-2 px-6 gap-1 bg-[#C4FA00] lg:bg-transparent lg:border-2 lg:border-[#C4FA00] lg:rounded-full">
            <Gift
              className="text-[#002F13] font-medium lg:text-white w-6 h-6"
              weight="bold"
            />
            <span className="text-[#002F13] font-medium lg:text-white">
              Indique e ganhe R$ 10,00
            </span>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="flex flex-col rounded-3xl p-4 lg:p-6 gap-3 lg:gap-[22px] z-50 w-[350px] lg:w-[690px] bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
            sideOffset={5}
            align={window.innerWidth < 768 ? "center" : "end"}
          >
            <span className="text-center font-extrabold text-[28px] text-[#393939]">
              MINHAS INDICAÇÕES
            </span>

            <Tabs.Root className="flex flex-col w-full" defaultValue="referral">
              <Tabs.List
                className="shrink-0 flex gap-0"
                aria-label="Manage your account"
              >
                <Tabs.Trigger
                  className="bg-white px-4 h-[40px] flex-1 flex items-center justify-center text-[14px] lg:text-[18px] text-black/35 font-regular leading-none border-b-[1px] border-b-[#EE7219]/30 select-none hover:text-black/45 data-[state=active]:text-black/85 data-[state=active]:font-semibold data-[state=active]:border-b-[3px] data-[state=active]:border-b-[#EE7219] outline-none cursor-default"
                  value="referral"
                >
                  Indicações
                </Tabs.Trigger>
                <Tabs.Trigger
                  className="bg-white px-4 h-[40px] flex-1 flex items-center justify-center text-[14px] lg:text-[18px] text-black/35 font-regular leading-none border-b-[1px] border-b-[#EE7219]/30 select-none hover:text-black/45 data-[state=active]:text-black/85 data-[state=active]:font-semibold data-[state=active]:border-b-[3px] data-[state=active]:border-b-[#EE7219] outline-none cursor-default"
                  value="toreceive"
                >
                  A Receber
                </Tabs.Trigger>
                <Tabs.Trigger
                  className="bg-white px-4 h-[40px] flex-1 flex items-center justify-center text-[14px] lg:text-[18px] text-black/35 font-regular leading-none border-b-[1px] border-b-[#EE7219]/30 select-none hover:text-black/45 data-[state=active]:text-black/85 data-[state=active]:font-semibold data-[state=active]:border-b-[3px] data-[state=active]:border-b-[#EE7219] outline-none cursor-default"
                  value="received"
                >
                  Recebidos
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content
                className="grow pt-3 bg-white rounded-b-md outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
                value="referral"
              >
                <ReferralTab profile={profile!} />
              </Tabs.Content>
              <Tabs.Content
                className="grow pt-3 bg-white rounded-b-md outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
                value="toreceive"
              >
                <ToReceiveTab profile={profile!} />
              </Tabs.Content>

              <Tabs.Content
                className="grow pt-3 bg-white rounded-b-md outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
                value="received"
              >
                <ReceivedTab profile={profile!} />
              </Tabs.Content>
            </Tabs.Root>
            <Popover.Arrow className="fill-white" width={32} height={16} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </main>
  );
}
