import { ResultCardProps, ResultResponseProps } from "@/@types/AppTypes";
import {
  ArrowRight,
  CalendarBlank,
  CurrencyCircleDollar,
  Info,
  Star,
  Ticket,
  Trophy,
} from "@phosphor-icons/react";
import * as HoverCard from "@radix-ui/react-hover-card";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/pt-br";
import { useState } from "react";
import DisplayWinnersModal from "../components/modals/DisplayWinnersModal";

export default function ResultCard({
  id,
  date,
  type,
  price,
  selected_color,
  winner_ticket,
  winners,
}: ResultResponseProps) {
  const [displayWinnersModal, setDisplayWinnersModal] = useState(false);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  const localEndTime = dayjs(date).tz(dayjs.tz.guess()).locale("pt-br");
  const formattedWinnerTicket = Number(winner_ticket).toLocaleString("en-US", {
    minimumIntegerDigits:
      type === "hour"
        ? 5
        : type === "day" || type === "week"
        ? 6
        : type === "month"
        ? 7
        : 8,
    useGrouping: false,
  });
  if (!winners) {
    return null;
  }

  const ColoredClover = () => {
    return (
      <div className="flex flex-col w-16 !h-20 px-2 items-center justify-center overflow-hidden">
        <svg
          className={`w-full h-full ${
            selected_color === 0
              ? "text-[#DDBE55]"
              : selected_color === 1
              ? "text-[#39B54A]"
              : selected_color === 2
              ? "text-[#0078E8]"
              : "text-[#EE7219]"
          } fill-current`}
          viewBox="0 0 20 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.192 3.1871L9.17068 9.15613C9.19704 8.81858 9.22221 8.53672 9.24451 8.33144C9.25729 8.21351 9.27939 8.06225 9.30485 7.88704C9.35341 7.55394 9.4199 7.09756 9.46231 6.59163C9.50106 6.12932 9.51859 5.68314 9.51453 5.26535C9.51186 5.00299 9.50066 4.74586 9.48114 4.50091C9.34132 2.74982 8.76946 1.47935 7.78152 0.724796C6.43527 -0.303399 5.29849 -0.0140242 4.7407 0.249925C4.4578 0.383878 4.20293 0.566901 3.98463 0.784847C3.44766 1.32096 3.13274 2.06789 3.17198 2.81017L3.192 3.1871Z"
            fill="currentColor"
          />
          <path
            d="M4.50812 9.46606C4.75347 9.48555 5.01111 9.49683 5.2737 9.4995C5.69216 9.50346 6.13906 9.48605 6.60212 9.44736C7.10887 9.40502 7.56598 9.33864 7.89962 9.29016C8.07511 9.26474 8.22662 9.24268 8.34474 9.22981C8.55016 9.20765 8.83247 9.18233 9.17067 9.15611L3.19209 3.18708L2.81455 3.1671C1.76874 3.11199 0.714204 3.75604 0.250159 4.73328C-0.0143166 5.29007 -0.304159 6.42501 0.725797 7.76919C1.48157 8.75554 2.7542 9.32637 4.50802 9.46606H4.50812Z"
            fill="currentColor"
          />
          <path
            d="M3.19201 15.8129L9.1707 9.84386C8.83259 9.81755 8.55028 9.79242 8.34467 9.77016C8.22655 9.7574 8.07504 9.73534 7.89955 9.70991C7.56591 9.66144 7.1088 9.59505 6.60205 9.55271C6.13899 9.51403 5.69209 9.49652 5.27363 9.50057C5.01084 9.50324 4.7533 9.51442 4.50795 9.53391C2.75403 9.6735 1.48149 10.2444 0.725724 11.2308C-0.304134 12.575 -0.0142914 13.7099 0.250085 14.2667C0.384254 14.5491 0.567574 14.8036 0.785872 15.0215C1.32285 15.5577 2.07099 15.8721 2.81447 15.8329L3.19201 15.8129Z"
            fill="currentColor"
          />
          <path
            d="M9.48114 14.499C9.50066 14.2541 9.51196 13.9968 9.51463 13.7347C9.5186 13.3169 9.50116 12.8707 9.46241 12.4084C9.42 11.9025 9.35351 11.4461 9.30496 11.113C9.27949 10.9378 9.25739 10.7865 9.24451 10.6686C9.22231 10.4635 9.19695 10.1816 9.17069 9.84399L3.192 15.813L3.17199 16.19C3.11679 17.2341 3.76188 18.2869 4.7407 18.7502C5.29839 19.0142 6.43517 19.3036 7.78152 18.2753C8.76947 17.5208 9.34122 16.2502 9.48114 14.4992V14.499Z"
            fill="currentColor"
          />
          <path
            d="M15.8383 15.813L9.85966 9.84399C9.8333 10.1815 9.80813 10.4634 9.78573 10.6687C9.77295 10.7866 9.75085 10.9379 9.72539 11.1131C9.67683 11.4462 9.61034 11.9026 9.56793 12.4085C9.52919 12.8708 9.51165 13.317 9.51571 13.7348C9.51839 13.9971 9.52958 14.2543 9.5491 14.4992C9.68892 16.2503 10.2608 17.5208 11.2487 18.2753C12.5951 19.3036 13.7319 19.0142 14.2895 18.7502C14.5724 18.6162 14.8273 18.4332 15.0456 18.2153C15.5826 17.6792 15.8975 16.9322 15.8583 16.19L15.8382 15.813H15.8383Z"
            fill="currentColor"
          />
          <path
            d="M14.5222 9.53399C14.2768 9.5145 14.0192 9.50322 13.7566 9.50055C13.3381 9.49659 12.8912 9.514 12.4282 9.55269C11.9214 9.59503 11.4643 9.66141 11.1307 9.70989C10.9552 9.73531 10.8037 9.75738 10.6855 9.77024C10.4801 9.7924 10.1978 9.81772 9.85962 9.84394L15.8383 15.813L16.2158 15.833C17.2617 15.8881 18.3162 15.244 18.7802 14.2668C19.0447 13.71 19.3345 12.575 18.3046 11.2309C17.5488 10.2445 16.2762 9.67368 14.5224 9.53399H14.5222Z"
            fill="currentColor"
          />
          <path
            d="M15.8613 3.18698L9.88257 9.15601C10.2207 9.18233 10.503 9.20745 10.7086 9.22981C10.8267 9.24258 10.9782 9.26464 11.1537 9.29006C11.4874 9.33854 11.9445 9.40492 12.4512 9.44726C12.9143 9.48595 13.3612 9.50346 13.7796 9.4994C14.0424 9.49673 14.3 9.48555 14.5453 9.46606C16.2992 9.32647 17.5718 8.75554 18.3275 7.76919C19.3575 6.42501 19.0677 5.29007 18.8032 4.73328C18.669 4.45083 18.4857 4.19638 18.2674 3.97843C17.7304 3.44232 16.9823 3.12792 16.2388 3.1671L15.8613 3.18708V3.18698Z"
            fill="currentColor"
          />
          <path
            d="M9.57213 4.50094C9.55261 4.74589 9.54131 5.00311 9.53864 5.26528C9.53467 5.68307 9.55211 6.12925 9.59086 6.59156C9.63327 7.09749 9.69976 7.55386 9.74831 7.88697C9.77378 8.06217 9.79588 8.21344 9.80876 8.33137C9.83096 8.53645 9.85632 8.81831 9.88258 9.15596L15.8613 3.18693L15.8813 2.81C15.9365 1.76588 15.2914 0.713051 14.3126 0.249755C13.7549 -0.0142935 12.6181 -0.303668 11.2717 0.724626C10.2838 1.47918 9.71205 2.74975 9.57213 4.50074V4.50094Z"
            fill="currentColor"
          />
        </svg>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col items-center px-3 pb-4 py-7 gap-1 w-[280px] min-w-[280px] max-w-[300px] h-fit bg-white shadow-2xl rounded-2xl">
      <div className="absolute -top-5 left-[50%] translate-x-[-50%] flex whitespace-nowrap items-center justify-center py-2 px-3 gap-2 rounded-full bg-[#EE7219]">
        <Star className="w-[18px] h-[18px] text-white" weight="fill" />
        <span className="text-[14px] font-medium text-white">
          {type === "hour"
            ? "PixHORA"
            : type === "year"
            ? "PixANO"
            : type === "month"
            ? "PixMÊS"
            : type === "week"
            ? "PixSEMANA"
            : "PixDIA"}
        </span>
      </div>

      <div className="flex  flex-col gap-1 items-center">
        {type === "hour" && <ColoredClover />}
        <div className="flex flex-row items-center px-3 py-1 gap-2 rounded-full bg-[#5C5C5C] text-white text-[11px]  font-semibold">
          <Ticket className="w-5 h-5 text-white" weight="bold" />
          <span className="leading-[28px] text-center">
            {formattedWinnerTicket}
          </span>
        </div>
      </div>

      <p className="text-[#5C5C5C] text-[18px] leading-[23px]">
        Valor Sorteado
      </p>
      <div className="flex flex-row items-center justify-center gap-2">
        <p className="text-2xl text-[#6DC032] opacity-80">R$</p>
        <p className="text-3xl lg:text-5xl font-bold text-[#6DC032]">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "code",
          })
            .format(Number(price))
            .replace("BRL", "")
            .trim()}
        </p>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 mt-2 mb-2">
        <CalendarBlank className="text-[#5C5C5C] w-5 h-5" />
        <p className="text-[#5C5C5C] leading-none text-[11px]">
          Data do Sorteio:{" "}
          {localEndTime.locale("pt-br").format("DD/MM/YYYY [às] HH:mm")}
        </p>
      </div>

      {winners && winners!.length == 1 ? (
        <div className="flex flex-row min-h-[54px]  w-full items-center justify-between gap-2 py-2 px-4 border border-[#D8D8D8] rounded-full">
          <p className="text-[#5C5C5C] text-[16px] tracking-wider font-medium">
            {winners![0].name}
          </p>

          <p className="text-[#6DC032] text-[13px] tracking-tight">
            {winners![0].document}
          </p>
        </div>
      ) : (
        <button
          className="flex flex-row w-full min-h-[54px] items-center justify-between gap-2 py-2 pl-2 pr-4 border border-[#D8D8D8] rounded-full"
          onClick={() => setDisplayWinnersModal(true)}
        >
          <div className="flex flex-row h-9 items-center justify-center py-0 px-4 gap-1 bg-[#6DC032] rounded-full">
            <Trophy className="w-6 h-6 lg:w-6 lg:h-6 text-white" />
            <span className="text-white tracking-wider font-semibold">
              {winners?.length}
            </span>
          </div>

          <span className="text-[#5C5C5C] font-medium">Ver ganhadores</span>
          <ArrowRight className="w-4 h-4 text-[#5C5C5C]" weight="bold" />
        </button>
      )}
      <DisplayWinnersModal
        isOpen={displayWinnersModal}
        setIsOpen={setDisplayWinnersModal}
        winners={winners!}
        selected_color={selected_color}
        ticket_number={formattedWinnerTicket}
      />
    </div>
  );
}
