import { ProfileResponse } from "@/@types/AppTypes";
import { Warning } from "@phosphor-icons/react";

export type ToReceiveTabProps = {
  profile: ProfileResponse;
};

export default function ToReceiveTab({ profile }: ToReceiveTabProps) {
  return (
    <section className="flex flex-col w-full gap-3">
      <section className="flex flex-col w-full gap-3">
        <div className="flex flex-col w-full text-center lg:text-start gap-3">
          <span className="font-extrabold text-[19px] lg:text-[25px] text-[#393939]">
            Valores a receber
          </span>
          <span className="font-regular text-[15px] lg:text-[17px] text-[#5E5E5E]">
            Acompanhe aqui os valores a receber!
          </span>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between bg-[#1A73E8]/10 rounded-xl p-3 gap-2">
          <div className="flex flex-col lg:flex-row items-center lg:px-6 lg:w-full lg:justify-between gap-[11px] text-[#1A73E8]">
            <span className="font-medium text-[17px]">
              Você tem para receber hoje
            </span>

            <div className="flex flex-row items-center gap-[6px]">
              <span className="text-[20px]">R$</span>
              <span className="font-bold text-[29px]">
                {new Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                  currencyDisplay: "code",
                })
                  .format(Number(profile?.effective_affiliates) * 10)
                  .replace("BRL", "")
                  .trim()}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-row lg:items-center px-6 py-4 gap-2 border-[1px] border-[#FCD28C] border-l-4 border-l-[#FCA004] bg-[#FFFBED] rounded-xl">
          <Warning
            className="min-w-5 min-h-5 mt-1 lg:mt-0 text-[#FCA004]"
            weight="bold"
          />
          <span className="text-[#181818] text-[14px] leading-[20px]">
            Todos os dias, às 18h, o total acumulado será transferido para sua
            conta.
          </span>
        </div>
      </section>
    </section>
  );
}
