import { ProfileResponse } from "@/@types/AppTypes";
import { UsersFour } from "@phosphor-icons/react";

export type ReceivedTabProps = {
  profile: ProfileResponse;
};

export default function ReceivedTab({ profile }: ReceivedTabProps) {
  return (
    <section className="flex flex-col w-full gap-3">
      <div className="flex flex-col w-full text-center lg:text-start gap-3">
        <span className="font-extrabold text-[19px] lg:text-[25px] text-[#393939]">
          Ganhos Recebidos
        </span>
        <span className="font-regular text-[15px] lg:text-[17px] text-[#5E5E5E]">
          Confira o total que você já ganhou com suas indicações. Continue
          convidando amigos para aumentar seus ganhos!
        </span>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-between bg-[#03A344]/10 rounded-xl p-3 lg:px-6 gap-2">
        <div className="flex flex-col lg:flex-row items-center gap-[11px] text-[#028136]">
          <span className="font-medium text-[17px]">
            Você já ganhou até hoje
          </span>

          <div className="flex flex-row items-center gap-[6px]">
            <span className="text-[20px]">R$</span>
            <span className="font-bold text-[29px]">
              {new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
                currencyDisplay: "code",
              })
                .format(Number(profile?.retrieved_amount))
                .replace("BRL", "")
                .trim()}
            </span>
          </div>
        </div>

        <button
          className={`flex flex-row items-center justify-center w-full lg:w-fit px-4 py-1 gap-3 rounded-full bg-white border-2 border-white hover:border-[#5F5F5F]`}
          onClick={() => {}}
          type="button"
        >
          <UsersFour className="w-6 h-6 text-[#5F5F5F]" weight="fill" />
          <span className="text-[#5F5F5F] font-medium text-[14px]">
            Ver indicações
          </span>
        </button>
      </div>
    </section>
  );
}
